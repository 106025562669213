<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <el-form ref="form"  label-width="120px">
                <el-form-item label="banner文字">
                    <el-input v-model="saveData.banner" style="width:500px;"></el-input>
                </el-form-item>

                <el-form-item label="二维码文字">
                    <el-input v-model="saveData.qun_word" style="width:500px;"></el-input>
                </el-form-item>

                <el-form-item label="技术群二维码" >
                    <el-upload
                        :action="uploadUrl"
                        :multiple="false"
                        :headers="authorizationBearer"
                        accept="image/png, image/jpeg"
                        :on-success="uploadFile">
                        <el-button size="small" >上传二维码</el-button>
                    </el-upload>
                    <img style="width:200px;" :src="baseURL+'/'+saveData.qrcode_qun" v-if="saveData.qrcode_qun != ''" alt="">
                </el-form-item> 
                <el-form-item >
                <el-button @click="save" type="primary">保存配置</el-button>
                </el-form-item> 
            </el-form> 
        </div>
    </div>
</template>

<script>
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import { get_return } from "../../../interface/http"
export default {
    name: "ListNei",
    inject: ['selectSide'], 
    data() {
        return {
            saveData:{
                banner:'',
                qrcode_qun:'',
                qun_word:''
            },
            uploadUrl: tools.baseURL() + 'api/admin/file',
            baseURL:tools.baseURL(),
            authorizationBearer: tools.getToken(),
        };
    },
    async created() {
        let url = 'api/report/purchase/index';
        let data = await get_return(url,{});
        console.log(data);

        //初始化
        apis.getConfig({
            
        }).then(res=>{
            let data = res.data;
            data.forEach(element => {
                this.saveData[element.name] = element.value;
            });
        }).catch(err=>{
            tools.err(err,this);
        });
    },
    methods:{
        // 获取上传的地址
        uploadFile(file) {
            if (file.code === 200) {
                this.saveData.qrcode_qun = file.font;
            } else {
                this.$message({
                    type: 'error',
                    message: file.msg
                });
            }
        },
        save(){
            let data = [];
            for(let i in this.saveData){
                let obj = {
                    name:i,
                    value:this.saveData[i]
                };
                data.push(obj);
            }

            apis.setConfig({
                data:JSON.stringify(data)
            }).then(res=>{
                tools.msg(res,this,1);
            }).catch(err=>{
                tools.err(err,this);
            });
        }
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>